import React, { useCallback, useEffect, useState } from "react";
//import cBioPortal from "cbioportal-api-client"
import { Row, Form, Button, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "./window.css";
import { CSVLink } from "react-csv";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Bar } from "react-chartjs-2";

import * as XLSX from "xlsx";

import logo from "../images/varstack2_logo.jpg";
import { isCompositeComponent } from "react-dom/test-utils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const cancer_types = require("../cancer-types.json");

let databases = new Map();
let csvMap = new Map();
let headersMap = new Map();

function newDatabase(c, f) {
  databases.set(c, f);
  csvMap.set(c, []);
  headersMap.set(c, []);
}

newDatabase("COSMIC", getCosmic);
newDatabase("ClinVar", getClinVar);
newDatabase("cBioPortal", getcBioPortal);
newDatabase("UCSC Genome Browser", getUCSC);
newDatabase("ClinicalTrials.gov", getClinicalTrials);

let peptides = ["Submit Gene ID First"];
let codons = ["Submit Gene ID First"];

async function getCosmic(gene, peptide, codon) {
  if (gene === "") {
    return "Please enter a gene name";
  }
  let link = "";
  if (peptide === "" && codon === "") {
    link = `https://clinicaltables.nlm.nih.gov/api/cosmic/v4/search?terms=${gene}&maxList=&grchv=38&df=AccessionNumber,GeneCDS_Length,GeneName,HGNC_ID,MutationAA,MutationCDS,MutationDescription,MutationGenomePosition,MutationStrand,MutationID,LegacyMutationID,GenomicMutationID,Name,PrimaryHistology,PrimarySite,PubmedPMID,Site,GRChVer`;
  } else if (peptide === "") {
    link = `https://clinicaltables.nlm.nih.gov/api/cosmic/v4/search?terms=${gene},${codon}&maxList=&grchv=38&df=AccessionNumber,GeneCDS_Length,GeneName,HGNC_ID,MutationAA,MutationCDS,MutationDescription,MutationGenomePosition,MutationStrand,MutationID,LegacyMutationID,GenomicMutationID,Name,PrimaryHistology,PrimarySite,PubmedPMID,Site,GRChVer`;
  } else if (codon === "") {
    link = `https://clinicaltables.nlm.nih.gov/api/cosmic/v4/search?terms=${gene},"${peptide}"&maxList=&grchv=38&df=AccessionNumber,GeneCDS_Length,GeneName,HGNC_ID,MutationAA,MutationCDS,MutationDescription,MutationGenomePosition,MutationStrand,MutationID,LegacyMutationID,GenomicMutationID,Name,PrimaryHistology,PrimarySite,PubmedPMID,Site,GRChVer`;
  } else {
    link = `https://clinicaltables.nlm.nih.gov/api/cosmic/v4/search?terms=${gene},"${peptide}",${codon}&maxList=&grchv=38&df=AccessionNumber,GeneCDS_Length,GeneName,HGNC_ID,MutationAA,MutationCDS,MutationDescription,MutationGenomePosition,MutationStrand,MutationID,LegacyMutationID,GenomicMutationID,Name,PrimaryHistology,PrimarySite,PubmedPMID,Site,GRChVer`;
  }
  console.log("cosmic link");
  console.log(link);
  let json = await fetch(link)
    .then((response) => response.json())
    .then((responseObject) => {
      let html = `<table> <tr> <th> Accession Number </th> <th> Gene CDS Length</th> <th> Gene Name </th> <th> HGNC ID </th> <th> Peptide Change </th> <th> Coding Sequence Change </th> <th> Mutation Description </th> <th> Mutation Genome Position </th> <th>Mutation Strand</th> <th>Mutation ID</th> <th>Legacy Mutation ID</th> <th>Genomic Mutation ID</th> <th>Primary Histology</th> <th>Primary Site</th> </tr>`;
      let temp = responseObject[3];
      let allCosmicData = [];
      let cosmicData = {};
      let cosmicHeaders = [
        "Accession Number",
        "Gene CDS Length",
        "Gene Name",
        "HGNC ID",
        "Peptide Change",
        "Coding Sequence Change",
        "Mutation Description",
        "Mutation Genome Position",
        "Mutation Strand",
        "Mutation ID",
        "Legacy Mutation ID",
        "Genomic Mutation ID",
        "placeholder",
        "Primary Histology",
        "Primary Site",
      ];
      for (let j = 0; j < temp.length; j++) {
        html += `<tr>`;
        for (let i = 0; i < 15; i++) {
          if (i !== 12) {
            html += `<td>${temp[j][i]}</td>`;
            cosmicData[cosmicHeaders[i]] = temp[j][i];
          }
        }
        allCosmicData.push(cosmicData);
        cosmicData = {};
        html += `</tr>`;
      }
      html += `</table>`;
      csvMap.set("Cosmic", allCosmicData);
      let cosmicHeaderObj = [];
      cosmicHeaders.forEach((ele) => {
        cosmicHeaderObj.push({ label: ele, key: ele });
      });
      headersMap.set("Cosmic", cosmicHeaderObj);
      return html;
    });

  return json;
}

async function getClinVar(gene, peptide, codon) {
  if (gene === "") {
    return "Please enter a gene name";
  }
  console.log("clinvar", gene, peptide, codon);
  let codonEscapedStr = codon.replace(">", "&gt;"); // such that when ">" is included in codon, it is used as text and not HTML
  let link = `https://www.ncbi.nlm.nih.gov/clinvar/?term="${gene}"[GENE]+"${peptide}"[VARNAME]+"${codonEscapedStr}"[VARNAME]`;
  console.log(link);
  csvMap.set("ClinVar", [{ link: link }]);
  headersMap.set("ClinVar", [{ label: "link", key: "link" }]);
  return `<iframe src=${link}></iframe>`;
}

export let chartOptions = {
  indexAxis: "y",
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: "right",
    },
    title: {
      display: true,
      text: "",
      font: {
        size: 24,
      },
    },
  },
};

export let chartData = {
  labels: [],
  datasets: [
    {
      label: "",
      data: [],
      borderColor: "rgb(53, 162, 235)",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};

async function getcBioPortal(gene, peptide, codon, cancer) {
  if (gene === "") {
    return "Please enter a gene name";
  }
  if (peptide.slice(0, 2) === "p.") {
    peptide = peptide.slice(2);
  }
  peptide = peptide.toLowerCase();
  let studyIDs = await fetch(
    `https://www.cbioportal.org/api/studies?direction=ASC&keyword=${cancer}&pageNumber=0&pageSize=10000000&projection=SUMMARY&sortBy=cancerTypeId`
  )
    .then((response) => response.json())
    .then((responseObject) => {
      let studies = [];
      responseObject.forEach((ele) => {
        studies.push(ele.studyId);
      });
      return studies;
    });

  let geneinfo = await fetch(`https://www.cbioportal.org/api/genes/${gene}`)
    .then((response) => response.json())
    .then((responseObject) => {
      return responseObject.entrezGeneId;
    });

  let mutationCount = new Map();
  for (let i = 0; i < studyIDs.length; i++) {
    let link = `https://www.cbioportal.org/api/molecular-profiles/${
      studyIDs[i] + "_mutations"
    }/mutations?direction=ASC&entrezGeneId=${geneinfo}&pageNumber=0&pageSize=10000000&projection=SUMMARY&sampleListId=${
      studyIDs[0] + "_all"
    }`;
    let mutations = await fetch(
      `https://www.cbioportal.org/api/molecular-profiles/${
        studyIDs[i] + "_mutations"
      }/mutations?direction=ASC&entrezGeneId=${geneinfo}&pageNumber=0&pageSize=10000000&projection=SUMMARY&sampleListId=${
        studyIDs[i] + "_all"
      }`
    )
      .then((response) => response.json())
      .then((responseObject) => {
        let count = 0;
        responseObject.forEach((ele) => {
          if (ele.proteinChange.toLowerCase() === peptide) {
            count++;
          }
        });
        return count;
      });
    mutationCount.set(studyIDs[i], mutations);
  }

  let link = `https://www.cbioportal.org/api/genes/${gene}`;

  /*
    let json = await fetch(link)
    .then(response => response.json())
    .then(responseObject => {
        let html = `<table> <tr> <th> Entrez Gene ID </th> <th> Hugo Gene Symbol </th> <th> Type </th> </tr>`
        console.log(responseObject)
        html += `<tr><td>${responseObject.entrezGeneId}</td><td>${responseObject.hugoGeneSymbol}</td><td>${responseObject.type}</td></tr>`
        html += `</table>`
        return html
    })
    */

  let chartLabels = [];

  chartData.datasets[0].data = [];
  let cBioData = {};
  let cBioHeaders = [];
  let html = `<div class="chart-wrap">
    <h2 class="title">Frequency of ${peptide} in ${gene}</h2>
    <div class="grid horizontal"> `;
  chartOptions.plugins.title.text = `Percentage of cases with ${peptide.toUpperCase()} in ${gene} `;
  let max = 0;
  mutationCount.forEach((value, key, map) => {
    if (value > max) {
      max = value;
    }
  });
  mutationCount.forEach((value, key, map) => {
    html += `<div class="bar" style="--bar-value:${
      (value / max) * 100
    }%;" data-name=${key} title=${value}></div>`;
    cBioHeaders.push(key);
    cBioData[key] = value;
    chartLabels.push(key);
    chartData.datasets[0].data.push((value / max) * 100);
    chartData.datasets[0].label = `${peptide.toUpperCase()}`;
  });
  html += `</div>
    </div>`;
  csvMap.set("cBioPortal", [cBioData]);
  let cBioHeaderObj = [];
  cBioHeaders.forEach((ele) => {
    cBioHeaderObj.push({ label: ele, key: ele });
  });
  headersMap.set("cBioPortal", cBioHeaderObj);

  chartData.labels = chartLabels;
  const test = <Bar options={chartOptions} data={chartData} />;

  return test;

  return html;
}

async function getUCSC(gene, peptide, codon) {
  if (gene === "") {
    return "Please enter a gene name";
  }
  let link = "";
  if (peptide === "" && codon === "") {
    link = `https://clinicaltables.nlm.nih.gov/api/cosmic/v4/search?terms=${gene}&maxList=&grchv=38&df=AccessionNumber,GeneCDS_Length,GeneName,HGNC_ID,MutationAA,MutationCDS,MutationDescription,MutationGenomePosition,MutationStrand,MutationID,LegacyMutationID,GenomicMutationID,Name,PrimaryHistology,PrimarySite,PubmedPMID,Site,GRChVer`;
  } else if (peptide === "") {
    link = `https://clinicaltables.nlm.nih.gov/api/cosmic/v4/search?terms=${gene},${codon}&maxList=&grchv=38&df=AccessionNumber,GeneCDS_Length,GeneName,HGNC_ID,MutationAA,MutationCDS,MutationDescription,MutationGenomePosition,MutationStrand,MutationID,LegacyMutationID,GenomicMutationID,Name,PrimaryHistology,PrimarySite,PubmedPMID,Site,GRChVer`;
  } else if (codon === "") {
    link = `https://clinicaltables.nlm.nih.gov/api/cosmic/v4/search?terms=${gene},${peptide}&maxList=&grchv=38&df=AccessionNumber,GeneCDS_Length,GeneName,HGNC_ID,MutationAA,MutationCDS,MutationDescription,MutationGenomePosition,MutationStrand,MutationID,LegacyMutationID,GenomicMutationID,Name,PrimaryHistology,PrimarySite,PubmedPMID,Site,GRChVer`;
  } else {
    link = `https://clinicaltables.nlm.nih.gov/api/cosmic/v4/search?terms=${gene},${peptide},${codon}&maxList=&grchv=38&df=AccessionNumber,GeneCDS_Length,GeneName,HGNC_ID,MutationAA,MutationCDS,MutationDescription,MutationGenomePosition,MutationStrand,MutationID,LegacyMutationID,GenomicMutationID,Name,PrimaryHistology,PrimarySite,PubmedPMID,Site,GRChVer`;
  }
  let position = await fetch(link)
    .then((response) => response.json())
    .then((responseObject) => {
      let temp = responseObject[3];
      return temp[0][7];
    });
  let linki = `https://genome.ucsc.edu/cgi-bin/hgTracks?db=hg38&lastVirtModeType=default&lastVirtModeExtraState=&virtModeType=default&virtMode=0&nonVirtPosition=&position=chr${position}&hgsid=1610342553_KAlVD6SwtlZYQeDcjFhLmAd0uEV2`;
  csvMap.set("UCSC Genome Browser", [{ link: linki }]);
  headersMap.set("UCSC Genome Browser", [{ label: "link", key: "link" }]);

  return `<iframe className = "UCSC-browser" src=${linki}> </iframe>`;
}

async function getClinicalTrials(gene, peptide, codon) {
  if (gene === "") {
    return "Please enter a gene name";
  }
  if (peptide.slice(0, 2) === "p.") {
    peptide = peptide.slice(2);
  }
  let link = "";
  //link = `https://classic.clinicaltrials.gov/api/query/full_studies?expr=+${gene}+${peptide}&min_rnk=1&max_rnk=10&fmt=json`;
  //link = `https://classic.clinicaltrials.gov/api/query/full_studies?expr=${gene}+${peptide}&min_rnk=1&max_rnk=50&fmt=json`;
  link = `https://clinicaltrials.gov/api/v2/studies?format=json&query.term=${gene}+${peptide}`;
  console.log("clinical trials", link);
  let json = await fetch(link)
    .then((response) => response.json())
    .then((responseObject) => {
      console.log("clinicalTrials responseObject", responseObject);
      let allTrialData = [];
      let trialData = {};
      let trialHeadings = [
        "Status",
        "Study Title",
        "Conditions",
        "Intervention",
        "Locations",
      ];
      let html = `<table> <tr> <th> Status </th> <th> Study Title</th> <th> Conditions </th> <th> Interventions </th> <th> Locations </th></tr>`;
      let studies = responseObject.studies;
      for (let i = 0; i < studies.length; i++) {
        let s = studies[i].protocolSection;
        html += `<tr>`;
        html += `<td>${s.statusModule.overallStatus}</td>`; // Status
        trialData["Status"] = s.statusModule.overallStatus;
        html += `<td>${s.identificationModule.briefTitle}</td>`; // Study Title
        trialData["Study Title"] = s.identificationModule.briefTitle;
        html += `<td><ul>`; // Conditions
        let conditiondata = "";
        for (let j = 0; j < s.conditionsModule.conditions.length; j++) {
          html += `<li>${s.conditionsModule.conditions[j]}</li>`;
          conditiondata += s.conditionsModule.conditions[j] + "\n";
        }
        html += `</ul></td>`;
        trialData["Conditions"] = conditiondata;
        html += `<td><ul>`; // Interventions
        let interventions = "";
        if (s.armsInterventionsModule) {
          if (s.armsInterventionsModule.interventions) {
            for (
              let j = 0;
              j < s.armsInterventionsModule.interventions.length;
              j++
            ) {
              html += `<li>${s.armsInterventionsModule.interventions[j].type}: ${s.armsInterventionsModule.interventions[j].name}</li>`;
              interventions +=
                s.armsInterventionsModule.interventions[j].type +
                ": " +
                s.armsInterventionsModule.interventions[j].name +
                "\n";
            }
          }
        } else {
          // if intervention does not exist
          interventions = html += `<li>Not Available</li>`;
        }
        html += `</ul></td>`;
        trialData["Interventions"] = interventions;
        html += `<td><ul>`; //Locations
        let locationdata = "";
        if (s.contactsLocationsModule) {
          if (!s.contactsLocationsModule.hasOwnProperty("locations")) {
            html += `No Location Data`;
            locationdata = `No Location Data`;
          } else {
            if (s.contactsLocationsModule.locations.length > 3) {
              for (let j = 0; j < 3; j++) {
                if (
                  !s.contactsLocationsModule.locations[j].hasOwnProperty(
                    "state"
                  )
                ) {
                  html += `<li>${s.contactsLocationsModule.locations[j].facility}, ${s.contactsLocationsModule.locations[j].city}, ${s.contactsLocationsModule.locations[j].country} </li>`;
                  locationdata +=
                    s.contactsLocationsModule.locations[j].facility +
                    ", " +
                    s.contactsLocationsModule.locations[j].city +
                    ", " +
                    s.contactsLocationsModule.locations[j].country +
                    "\n";
                } else {
                  html += `<li>${s.contactsLocationsModule.locations[j].facility}, ${s.contactsLocationsModule.locations[j].city}, ${s.contactsLocationsModule.locations[j].state}, ${s.contactsLocationsModule.locations[j].country} </li>`;
                  locationdata +=
                    s.contactsLocationsModule.locations[j].facility +
                    ", " +
                    s.contactsLocationsModule.locations[j].city +
                    ", " +
                    s.contactsLocationsModule.locations[j].state +
                    ", " +
                    s.contactsLocationsModule.locations[j].country +
                    "\n";
                }
              }
              html += `<li>(and ${
                s.contactsLocationsModule.locations.length - 3
              } more ...)</li>`;
              locationdata += `(and ${
                s.contactsLocationsModule.locations.length - 3
              } more ...)`;
            } else {
              for (
                let j = 0;
                j < s.contactsLocationsModule.locations.length;
                j++
              ) {
                if (
                  !s.contactsLocationsModule.locations[j].hasOwnProperty(
                    "state"
                  )
                ) {
                  html += `<li>${s.contactsLocationsModule.locations[j].facility}, ${s.contactsLocationsModule.locations[j].city}, ${s.contactsLocationsModule.locations[j].country} </li>`;
                  locationdata += `${s.contactsLocationsModule.locations[j].facility}, ${s.contactsLocationsModule.locations[j].city}, ${s.contactsLocationsModule.locations[j].country} \n`;
                } else {
                  html += `<li>${s.contactsLocationsModule.locations[j].facility}, ${s.contactsLocationsModule.locations[j].city}, ${s.contactsLocationsModule.locations[j].state}, ${s.contactsLocationsModule.locations[j].country} </li>`;
                  locationdata += `${s.contactsLocationsModule.locations[j].facility}, ${s.contactsLocationsModule.locations[j].city}, ${s.contactsLocationsModule.locations[j].state}, ${s.contactsLocationsModule.locations[j].country} \n`;
                }
              }
            }
          }
        } else {
          html += `<li>Not Applicable</li>`;
        }
        html += `</ul></td>`;
        trialData["Locations"] = locationdata;
        html += `</tr>`;
        allTrialData.push(trialData);
        trialData = {};
      }
      html += `</table>`;
      csvMap.set("ClinicalTrials.gov", allTrialData);
      let trialHeaderObj = [];
      trialHeadings.forEach((ele) => {
        trialHeaderObj.push({ label: ele, key: ele });
      });
      headersMap.set("ClinicalTrials.gov", trialHeaderObj);
      return html;
    });
  return json;
}

function DataContainer({ dataset, gene, peptide, codon, cancer }) {
  const [output, setOutput] = useState("");
  const [header, setHeader] = useState("");
  const [data, setData] = useState();

  const func = databases.get(dataset);
  const fetchData = useCallback(async () => {
    const newOutput = await func(gene, peptide, codon, cancer);
    setOutput(newOutput);
  });
  useEffect(() => {
    fetchData().catch(console.error);
  }, [fetchData]);
  if (dataset == "cBioPortal") {
    return (
      <div className="data-container">
        <div className="dataset-name">{dataset}</div>
        <div className="csv">
          <CSVLink
            data={csvMap.get(dataset)}
            headers={headersMap.get(dataset)}
            filename={`Varstack2-${dataset}${gene}${peptide}${codon}.csv`}
          >
            Download as CSV
          </CSVLink>
        </div>
        {output}
      </div>
    );
  }
  return (
    <div className="data-container">
      <div className="dataset-name">{dataset}</div>
      <div className="csv">
        <CSVLink
          data={csvMap.get(dataset)}
          headers={headersMap.get(dataset)}
          filename={`Varstack2-${dataset}${gene}${peptide}${codon}.csv`}
        >
          Download as CSV
        </CSVLink>
      </div>
      <div
        className="data-table"
        dangerouslySetInnerHTML={{ __html: output }}
      ></div>
    </div>
  );
}

function OneInput({ value, setValue, placeholder, label }) {
  return (
    // <input role="textbox" className="input-box" placeholder = {placeholder}
    //         value={value}
    //        onChange={(ev) => setValue(ev.target.value)}
    //        ></input>
    <div className="input_fields_main">
      <Form>
        <Form.Group>
          <Form.Label>{label}</Form.Label>
          <Form.Control
            type="text"
            placeholder={placeholder}
            value={value}
            onChange={(ev) => setValue(ev.target.value)}
          />
        </Form.Group>
      </Form>
    </div>
  );
}

function CancerSelect({ value, setValue }) {
  return (
    <select
      className="input-box-cancer"
      value={value}
      onChange={(ev) => setValue(ev.target.value)}
    >
      <option value="choose" disabled selected="selected">
        {" "}
        -- Select Cancer --
      </option>{" "}
      {ListCancers()}
    </select>
  );
}

function ListCancers() {
  return cancer_types.map((cancer) => {
    return <option value={cancer.cancerTypeId}>{cancer.name}</option>;
  });
}

function ListElement(element) {
  return element.map((ele) => {
    if (ele == "Select Here") {
      return (
        <option value={ele} selected>
          {ele}
        </option>
      );
    }
    return <option value={ele}>{ele}</option>;
  });
}

function SmartSearchCodon({ gene, peptide, codon, putElement }) {
  const [element, setElement] = useState("");
  const [codonList, setCodonList] = useState([]);
  const onChange = (ev) => {
    setElement(ev.target.value);
    putElement(ev.target.value);
  };
  const fetchData = useCallback(async () => {
    let link = "";
    if (peptide === "" && codon === "") {
      link = `https://clinicaltables.nlm.nih.gov/api/cosmic/v4/search?terms=${gene}&maxList=&grchv=38&df=AccessionNumber,GeneCDS_Length,GeneName,HGNC_ID,MutationAA,MutationCDS,MutationDescription,MutationGenomePosition,MutationStrand,MutationID,LegacyMutationID,GenomicMutationID,Name,PrimaryHistology,PrimarySite,PubmedPMID,Site,GRChVer`;
    } else if (peptide === "") {
      link = `https://clinicaltables.nlm.nih.gov/api/cosmic/v4/search?terms=${gene},${codon}&maxList=&grchv=38&df=AccessionNumber,GeneCDS_Length,GeneName,HGNC_ID,MutationAA,MutationCDS,MutationDescription,MutationGenomePosition,MutationStrand,MutationID,LegacyMutationID,GenomicMutationID,Name,PrimaryHistology,PrimarySite,PubmedPMID,Site,GRChVer`;
    } else if (codon === "") {
      link = `https://clinicaltables.nlm.nih.gov/api/cosmic/v4/search?terms=${gene},"${peptide}"&maxList=&grchv=38&df=AccessionNumber,GeneCDS_Length,GeneName,HGNC_ID,MutationAA,MutationCDS,MutationDescription,MutationGenomePosition,MutationStrand,MutationID,LegacyMutationID,GenomicMutationID,Name,PrimaryHistology,PrimarySite,PubmedPMID,Site,GRChVer`;
    } else {
      link = `https://clinicaltables.nlm.nih.gov/api/cosmic/v4/search?terms=${gene},"${peptide}",${codon}&maxList=&grchv=38&df=AccessionNumber,GeneCDS_Length,GeneName,HGNC_ID,MutationAA,MutationCDS,MutationDescription,MutationGenomePosition,MutationStrand,MutationID,LegacyMutationID,GenomicMutationID,Name,PrimaryHistology,PrimarySite,PubmedPMID,Site,GRChVer`;
    }

    codons = await fetch(link)
      .then((response) => response.json())
      .then((responseObject) => {
        let temp = responseObject[3];
        let codonArray = [];

        for (let j = 0; j < temp.length; j++) {
          if (!temp[j][5].includes("?")) {
            if (!codonArray.includes(temp[j][5])) {
              codonArray.push(temp[j][5]);
            }
          }
        }
        codonArray.sort();
        codonArray.unshift("Select Here");
        return codonArray;
      });
    setCodonList(codons);
  });
  useEffect(() => {
    fetchData().catch(console.error);
  }, [fetchData]);
  return (
    <div className="smart-search">
      <div className="input-box">
        <div className="peptide_change_selector">
          Choose Coding Sequence Change in Gene <br />
          <select
            className="input-box-peptide"
            value={element}
            onChange={onChange}
          >
            <option value="choose" disabled selected="selected">
              {" "}
              -- Select Coding Sequence Change --
            </option>{" "}
            {ListElement(codonList)}
          </select>
        </div>
      </div>
    </div>
  );
}

function SmartSearch({ putElement, eleName, eleList }) {
  const [element, setElement] = useState("");
  const onChange = (ev) => {
    putElement(ev.target.value);
    setElement(ev.target.value);
  };
  return (
    <div className="smart-search">
      <div className="input-box">
        <div className="peptide_change_selector">
          Choose {eleName} Change in Gene <br />
          <select
            className="input-box-peptide"
            value={element}
            onChange={onChange}
          >
            <option value="choose" disabled selected="selected">
              {" "}
              -- Select {eleName} Change --
            </option>{" "}
            {ListElement(eleList)}
          </select>
        </div>
      </div>
    </div>
  );
}

function GeneInputs({
  curPeptide,
  putGene,
  putPeptide,
  putCodon,
  putCancer,
  putPeptideList,
  putCodonList,
}) {
  const [gene, setGene] = useState("");
  const [peptide, setPeptide] = useState("");
  const [codon, setCodon] = useState("");
  const [cancer, setCancer] = useState("");
  const [peptideList, setPeptideList] = useState("");
  const [codonList, setCodonList] = useState("");
  return (
    <Row className="inputs">
      <div className="input-boxes">
        {" "}
        <OneInput
          label="Enter Gene Value"
          value={gene}
          setValue={setGene}
          placeholder="KRAS"
        />{" "}
        <OneInput
          label="Enter Amino Acid Change"
          value={peptide}
          setValue={setPeptide}
          placeholder="p.G12V"
        />{" "}
        <OneInput
          label="Enter Coding Sequence Change"
          value={codon}
          setValue={setCodon}
          placeholder="c.35G>T"
        />
        <div className="cancer_select">
          {" "}
          <CancerSelect
            label="Enter Cancer Type (for cBioPortal)"
            value={cancer}
            setValue={setCancer}
          />
        </div>
      </div>
      <div className="btn_container">
        <button
          className="submit-button"
          role="button"
          onClick={async () => {
            putGene(gene);
            putPeptide(peptide);
            putCodon(codon);
            putCancer(cancer);
            let link = "";
            if (peptide === "" && codon === "") {
              link = `https://clinicaltables.nlm.nih.gov/api/cosmic/v4/search?terms=${gene}&maxList=&grchv=38&df=AccessionNumber,GeneCDS_Length,GeneName,HGNC_ID,MutationAA,MutationCDS,MutationDescription,MutationGenomePosition,MutationStrand,MutationID,LegacyMutationID,GenomicMutationID,Name,PrimaryHistology,PrimarySite,PubmedPMID,Site,GRChVer`;
            } else if (peptide === "") {
              link = `https://clinicaltables.nlm.nih.gov/api/cosmic/v4/search?terms=${gene},${codon}&maxList=&grchv=38&df=AccessionNumber,GeneCDS_Length,GeneName,HGNC_ID,MutationAA,MutationCDS,MutationDescription,MutationGenomePosition,MutationStrand,MutationID,LegacyMutationID,GenomicMutationID,Name,PrimaryHistology,PrimarySite,PubmedPMID,Site,GRChVer`;
            } else if (codon === "") {
              link = `https://clinicaltables.nlm.nih.gov/api/cosmic/v4/search?terms=${gene},"${peptide}"&maxList=&grchv=38&df=AccessionNumber,GeneCDS_Length,GeneName,HGNC_ID,MutationAA,MutationCDS,MutationDescription,MutationGenomePosition,MutationStrand,MutationID,LegacyMutationID,GenomicMutationID,Name,PrimaryHistology,PrimarySite,PubmedPMID,Site,GRChVer`;
            } else {
              link = `https://clinicaltables.nlm.nih.gov/api/cosmic/v4/search?terms=${gene},"${peptide}",${codon}&maxList=&grchv=38&df=AccessionNumber,GeneCDS_Length,GeneName,HGNC_ID,MutationAA,MutationCDS,MutationDescription,MutationGenomePosition,MutationStrand,MutationID,LegacyMutationID,GenomicMutationID,Name,PrimaryHistology,PrimarySite,PubmedPMID,Site,GRChVer`;
            }
            peptides = await fetch(link)
              .then((response) => response.json())
              .then((responseObject) => {
                let temp = responseObject[3];
                let peptideArray = [];

                for (let j = 0; j < temp.length; j++) {
                  if (!temp[j][4].includes("?")) {
                    if (!peptideArray.includes(temp[j][4])) {
                      peptideArray.push(temp[j][4]);
                    }
                  }
                }
                peptideArray.sort();
                peptideArray.unshift("Select Here");

                return peptideArray;
              });
            setPeptideList(peptides);
            putPeptideList(peptides);
            codons = await fetch(link)
              .then((response) => response.json())
              .then((responseObject) => {
                let temp = responseObject[3];
                let codonArray = [];

                for (let j = 0; j < temp.length; j++) {
                  if (!temp[j][5].includes("?")) {
                    if (!codonArray.includes(temp[j][5])) {
                      codonArray.push(temp[j][5]);
                    }
                  }
                }
                codonArray.sort();
                return codonArray;
              });
            setCodonList(codons);
            putCodonList(codons);
          }}
        >
          SUBMIT
        </button>
      </div>
    </Row>
  );
}

function handleOnExport(gene, peptide, codon) {
  let wb = XLSX.utils.book_new();
  let ws_1 = XLSX.utils.json_to_sheet(csvMap.get("COSMIC"));
  XLSX.utils.book_append_sheet(wb, ws_1, "COSMIC");
  let ws_2 = XLSX.utils.json_to_sheet(csvMap.get("ClinVar"));
  XLSX.utils.book_append_sheet(wb, ws_2, "ClinVar");
  let ws_3 = XLSX.utils.json_to_sheet(csvMap.get("cBioPortal"));
  XLSX.utils.book_append_sheet(wb, ws_3, "cBioPortal");
  let ws_4 = XLSX.utils.json_to_sheet(csvMap.get("UCSC Genome Browser"));
  XLSX.utils.book_append_sheet(wb, ws_4, "UCSC Genome Browser");
  let ws_5 = XLSX.utils.json_to_sheet(csvMap.get("ClinicalTrials.gov"));
  XLSX.utils.book_append_sheet(wb, ws_5, "ClinicalTrials.gov");

  XLSX.writeFile(wb, `Varstack2${gene},${peptide},${codon}.xlsx`);
}

export default function Window() {
  const [gene, setGene] = useState("");
  const [peptide, setPeptide] = useState("");
  const [codon, setCodon] = useState("");
  const [cancer, setCancer] = useState("");
  const [peptideList, setPeptideList] = useState("");
  const [codonList, setCodonList] = useState("");

  let rows = [];
  for (let [key, value] of databases) {
    rows.push(
      <DataContainer
        dataset={key}
        gene={gene}
        peptide={peptide}
        codon={codon}
        cancer={cancer}
      />
    );
  }
  return (
    <div className="window">
      <img className="varstack-logo" src={logo} alt="" />
      <div className="parent">
        <div className="child data">
          <div className="input">
            <GeneInputs
              curPeptide={peptide}
              putGene={(newGene) => {
                setGene(newGene);
              }}
              putPeptide={(newPeptide) => {
                setPeptide(newPeptide);
              }}
              putCodon={(newCodon) => {
                setCodon(newCodon);
              }}
              putCancer={(newCancer) => {
                setCancer(newCancer);
              }}
              putPeptideList={(newPeptideList) => {
                setPeptideList(newPeptideList);
              }}
              putCodonList={(newCodonList) => {
                setCodonList(newCodonList);
              }}
            />
          </div>
          <div>
            <SmartSearch
              putElement={(newPeptide) => {
                setPeptide(newPeptide);
              }}
              eleName={"Amino Acid"}
              eleList={peptides}
            />
            <SmartSearchCodon
              putElement={(newCodon) => {
                setCodon(newCodon);
              }}
              gene={gene}
              codon={codon}
              peptide={peptide}
            />
          </div>
        </div>
      </div>
      <div style={{ marginTop: "0.5rem" }}>
        <Button
          className="submit-button"
          onClick={() => handleOnExport(gene, peptide, codon)}
        >
          Export All Databases
        </Button>
      </div>
      <div className="data-container-list">{rows}</div>
    </div>
  );
}
